import RicherText from '@blocks/RicherText';
import React, { ReactElement } from 'react';
import Container from '@components/ui/container';
import Grid from '@components/grid';
import { ASSET_URL } from '@lib/constants';
import Image from 'next/image';
import Button from '@components/ui/button';
import CMSLink from '@components/CMSLink';
import Divider from '@blocks/Divider';
import cn from 'classnames';
import Text from '@components/ui/text';

function renderImage(image: any) {
  return (
    <Image
      src={
        image
          ? ASSET_URL + '/' + image.filename
          : '/assets/images/telesoft-soc.png'
      }
      loading="lazy"
      alt={image?.alt || ''}
      layout="fill"
      className="object-cover object-center"
    />
  );
}

function renderVideo(video: any) {
  return (
    <div
      className="relative w-full h-full overflow-hidden"
      style={{ minHeight: '280px' }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${video.embedId}?controls=1`}
        frameBorder="0"
        loading="lazy"
        className="w-full h-full"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

function renderText(obj: any) {
  if (!obj || !Object.prototype.hasOwnProperty.call(obj, 'options')) {
    return '';
  }

  const { theme, isSubtitle, subTitle, titleColour, textAlignment } =
    obj.options;
  const renderTitle = () => {
    if (Object.prototype.hasOwnProperty.call(obj, 'title')) {
      return (
        <div className="pl-4 md:pl-8 lg:pl-16">
          {isSubtitle && (
            <h4
              className={cn('mb-3', {
                'text-white': theme === 'light',
                'text-black': theme === 'dark',
              })}
            >
              {subTitle}
            </h4>
          )}
          <Text
            variant="subHeading"
            className={cn('pb-4 md:pb-6 lg:pb-8 w-full', {
              'text-telesoft': titleColour === 'blue',
              'text-white': titleColour === 'light',
              'text-black': titleColour === 'black',
            })}
          >
            {obj.title}
          </Text>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={cn(`mx-auto flex flex-col justify-center`, {
          'text-center items-center': textAlignment === 'text-center',
          'text-left items-start': textAlignment === 'text-left',
        })}
      >
        {renderTitle()}

        <RicherText
          container={true}
          theme={theme}
          content={obj.description}
          className={cn('max-w-4xl', {
            'text-white': theme === 'light',
            'text-black': theme === 'dark',
          })}
        />
      </div>
    </>
  );
}

function renderButton(obj: any) {
  const { link, buttonStyle, invert } = obj;
  return (
    <CMSLink {...link}>
      <Button
        className="content-center mt-0 mb-8"
        // @ts-expect-error
        variant={`${buttonStyle}`}
        invert={invert}
      >
        {link.label || 'Learn More'}
      </Button>
    </CMSLink>
  );
}

function FullWidthDynamicBlock(block: any): ReactElement {
  const { columns, background, dividerBottom, dividerTop, links } = block;

  const gradient = () => {
    if (background.length > 0) {
      if (Object.prototype.hasOwnProperty.call(background[0], 'gradient')) {
        const gradient = background[0].gradient;
        if (gradient === 'none') {
          return '';
        } else if (gradient === 'blue-to-transparent') {
          return 'mix-blend-soft-light bg-gradient-to-b from-telesoft to-transparent';
        } else if (gradient === 'transparent-to-blue') {
          return 'mix-blend-soft-light bg-gradient-to-b from-transparent to-telesoft';
        } else if (gradient === 'black-to-transparent') {
          return 'mix-blend-soft-light bg-gradient-to-b from-black to-transparent';
        } else if (gradient === 'transparent-to-black') {
          return 'mix-blend-soft-light bg-gradient-to-b from-transparent to-black';
        }
      }
    }
    return '';
  };

  const backgroundColour = () => {
    if (background.length > 0) {
      if (background[0].backgroundColour.length > 0) {
        const colour = background[0].backgroundColour[0];
        if (Object.prototype.hasOwnProperty.call(colour, 'color')) {
          return colour.color;
        } else {
          return 'transparent';
        }
      }
    }
    return 'transparent';
  };

  const backgroundImage = () => {
    if (background.length > 0) {
      if (
        Object.prototype.hasOwnProperty.call(background[0], 'backgroundImage')
      ) {
        if (background[0].backgroundImage.length > 0) {
          const image = background[0].backgroundImage.bgImage;
          return renderImage(image);
        }
      }
    }
  };

  const borderTop = () => {
    if (dividerTop === 'none') {
      return '';
    } else if (dividerTop === 'blackline') {
      return <Divider withLine={true} />;
    } else if (dividerTop === 'noline') {
      return <Divider withLine={false} />;
    }
  };

  const borderBottom = () => {
    if (dividerBottom === 'none') {
      return '';
    } else if (dividerBottom === 'blackline') {
      return <Divider withLine={true} />;
    } else if (dividerBottom === 'noline') {
      return <Divider withLine={false} />;
    }
  };

  const renderContent = () => {
    if (columns.length === 1) {
      return (
        <>
          <div className="relative col-span-full min-h-[240px] lg:min-h-[300px] z-20 mb-8 flex items-center justify-center">
            {renderColumn()}
          </div>
        </>
      );
    } else {
      return <>{renderColumn(true)}</>;
    }
  };

  const renderColumn = (split = false) => {
    return columns.map((column: any, i: number) => {
      if (column.activatedContent === 'image') {
        if (column.images.length <= 0) {
          return (
            <div
              key={`items-map-${i}`}
              className="relative col-span-full lg:col-span-6 min-h-[240px] lg:max-w-[560px] z-20"
            ></div>
          );
        }
        const { image } = column.images[0];
        if (split) {
          return (
            <div
              key={`items-map-${i}`}
              className="relative col-span-full lg:col-span-6 min-h-[240px] lg:min-h-[300px] lg:max-w-[560px] z-20"
            >
              {renderImage(image)}
            </div>
          );
        } else {
          return <div key={`items-map-${i}`}>{renderImage(image)}</div>;
        }
      } else if (column.activatedContent === 'video') {
        if (column.videos.length <= 0) {
          return (
            <div
              key={`items-map-${i}`}
              className="mx-auto md:col-span-4 lg:col-span-6 z-20 flex flex-col justify-center text-left items-start"
            ></div>
          );
        }
        const video = column.videos[0];
        if (split) {
          return (
            <div
              key={`items-map-${i}`}
              className="mx-auto col-span-full w-full lg:col-span-6 z-20 flex flex-col justify-center text-left items-start"
            >
              {renderVideo(video)}
            </div>
          );
        } else {
          return <div key={`items-map-${i}`}>{renderVideo(video)}</div>;
        }
      } else if (column.activatedContent === 'text') {
        if (column.texts.length <= 0) {
          return (
            <div
              key={`items-map-${i}`}
              className="mx-auto md:col-span-4 lg:col-span-6 z-20 flex flex-col justify-center text-left items-start"
            ></div>
          );
        }
        const text = column.texts[0];
        if (split) {
          return (
            <div
              key={`items-map-${i}`}
              className="mx-auto col-span-full lg:col-span-6 z-20 mb-8 flex flex-col justify-center text-left items-start"
            >
              {renderText(text)}
            </div>
          );
        } else {
          return <div key={`items-map-${i}`}>{renderText(text)}</div>;
        }
      }
    });
  };

  const renderLinks = () => {
    return links.map((link: any, i: number) => {
      return (
        <div key={`items-map-${i}`} className="inline-block mr-4">
          {renderButton(link)}
        </div>
      );
    });
  };

  return (
    <>
      {borderTop()}
      <div className="mx-auto px-4 relative md:px-8 2xl:px-16 py-6 lg:py-7 xl:py-8">
        <div
          style={{ backgroundColor: `${backgroundColour()}` }}
          className={`absolute inset-0 z-10 ${gradient()}`}
        />
        <div className={`absolute inset-0`}>{backgroundImage()}</div>
        <Container>
          <Grid>
            {renderContent()}
            <div className="mx-auto col-span-full z-20 flex-col justify-center text-left items-start inline-block -mt-8">
              {renderLinks()}
            </div>
          </Grid>
        </Container>
      </div>
      {borderBottom()}
    </>
  );
}

export default FullWidthDynamicBlock;
